const players = [
  {
    id: 1,
    name: 'Frahmba',
    teamIds: [
      { league: process.env.REACT_APP_ENG_ID, team: 50, id: 1 },
      { league: process.env.REACT_APP_ESP_ID, team: 529, id: 2 },
      { league: process.env.REACT_APP_ESP_ID, team: 530, id: 3 },
      { league: process.env.REACT_APP_ESP_ID, team: 533, id: 4 },
      { league: process.env.REACT_APP_FRA_ID, team: 91, id: 5 },
      { league: process.env.REACT_APP_FRA_ID, team: 79, id: 6 },
      { league: process.env.REACT_APP_GER_ID, team: 160, id: 7 },
      { league: process.env.REACT_APP_GER_ID, team: 168, id: 8 },
      { league: process.env.REACT_APP_GER_ID, team: 162, id: 9 },
      { league: process.env.REACT_APP_ITA_ID, team: 489, id: 10 },
      { league: process.env.REACT_APP_ITA_ID, team: 494, id: 11 },
      { league: process.env.REACT_APP_ITA_ID, team: 496, id: 12 },
    ],
  },
  {
    id: 2,
    name: 'Mo',
    teamIds: [
      { league: process.env.REACT_APP_ENG_ID, team: 47, id: 1 },
      { league: process.env.REACT_APP_ESP_ID, team: 548, id: 2 },
      { league: process.env.REACT_APP_ESP_ID, team: 543, id: 3 },
      { league: process.env.REACT_APP_ESP_ID, team: 531, id: 4 },
      { league: process.env.REACT_APP_FRA_ID, team: 85, id: 5 },
      { league: process.env.REACT_APP_FRA_ID, team: 94, id: 6 },
      { league: process.env.REACT_APP_FRA_ID, team: 97, id: 7 },
      { league: process.env.REACT_APP_FRA_ID, team: 96, id: 8 },
      { league: process.env.REACT_APP_GER_ID, team: 157, id: 9 },
      { league: process.env.REACT_APP_ITA_ID, team: 505, id: 10 },
      { league: process.env.REACT_APP_ITA_ID, team: 499, id: 11 },
      { league: process.env.REACT_APP_ITA_ID, team: 497, id: 12 },
    ],
  },
  {
    id: 3,
    name: 'Mooch',
    teamIds: [
      { league: process.env.REACT_APP_ENG_ID, team: 42, id: 1 },
      { league: process.env.REACT_APP_ENG_ID, team: 40, id: 2 },
      { league: process.env.REACT_APP_ENG_ID, team: 48, id: 3 },
      { league: process.env.REACT_APP_ENG_ID, team: 55, id: 4 },
      { league: process.env.REACT_APP_ENG_ID, team: 36, id: 5 },
      { league: process.env.REACT_APP_FRA_ID, team: 116, id: 6 },
      { league: process.env.REACT_APP_FRA_ID, team: 80, id: 7 },
      { league: process.env.REACT_APP_GER_ID, team: 173, id: 8 },
      { league: process.env.REACT_APP_GER_ID, team: 182, id: 9 },
      { league: process.env.REACT_APP_GER_ID, team: 192, id: 10 },
      { league: process.env.REACT_APP_ITA_ID, team: 492, id: 11 },
      { league: process.env.REACT_APP_ITA_ID, team: 488, id: 12 },
    ],
  },
  {
    id: 4,
    name: 'Weast',
    teamIds: [
      { league: process.env.REACT_APP_ENG_ID, team: 49, id: 1 },
      { league: process.env.REACT_APP_ENG_ID, team: 34, id: 2 },
      { league: process.env.REACT_APP_ENG_ID, team: 33, id: 3 },
      { league: process.env.REACT_APP_ENG_ID, team: 51, id: 4 },
      { league: process.env.REACT_APP_ESP_ID, team: 541, id: 5 },
      { league: process.env.REACT_APP_ESP_ID, team: 532, id: 6 },
      { league: process.env.REACT_APP_ESP_ID, team: 720, id: 7 },
      { league: process.env.REACT_APP_FRA_ID, team: 81, id: 8 },
      { league: process.env.REACT_APP_GER_ID, team: 165, id: 9 },
      { league: process.env.REACT_APP_GER_ID, team: 169, id: 10 },
      { league: process.env.REACT_APP_GER_ID, team: 164, id: 11 },
      { league: process.env.REACT_APP_ITA_ID, team: 487, id: 12 },
    ],
  },
];

export default players;
